// @ts-nocheck

import { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { sortBy } from 'lodash-es';

import { RetailMenuFoodItem } from '@calo/dashboard-types';

import { RetailMenuItemTag } from '@calo/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getListWithParams, selectedFoodData } from 'actions';
import Input from 'components/Input';
import { handleSearch } from 'lib/helpers';
import { FoodComponent, MenuFood } from 'lib/interfaces';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from 'react-query';
import Button from '../Button';
import Modal from '../Modal';
import SelectMUI from '../MUI/SelectMUI';
import Select from '../Select';

interface RetailPickerProps {
  retailRef: any;
  retailList: MenuFood[];
  retailItem: RetailMenuFoodItem;
  existRetail: RetailMenuFoodItem[];
  setFilterName?: (value: string) => void;
  handleSelectedRetailItem?: (id: string) => void;
  AddToTable: (value: RetailMenuFoodItem) => void;
  isLoading: boolean;
  onClose: () => void;
}

// const retailTagsOptions = Object.values(RetailMenuItemTag).map((item) => ({ label: item, value: item }));
const retailTagsOptions = Object.values(RetailMenuItemTag).map((item) => ({ label: item, value: item }));

const RetailPicker = ({
  AddToTable,
  retailRef,
  retailList,
  retailItem,
  existRetail,
  setFilterName,
  handleSelectedRetailItem,
  isLoading,
  onClose
}: RetailPickerProps) => {
  const [currentRetailItem, setCurrentRetailItem] = useState<RetailMenuFoodItem | undefined>(retailItem);

  const [currentComponentName, setCurrentComponentName] = useState<string>('salad');
  const foodComponentSample = {
    name: 'salad',
    country: 'BH',
    brand: 'CALO',
    kitchen: 'BH001'
  };

  const { data: foodComponentData, isLoading: isLoadingFoodComponents } = useQuery<{ data: FoodComponent[] }, Error>(
    [
      'food-components',
      {
        filters: {
          name: currentComponentName,
          country: foodComponentSample?.country,
          brand: foodComponentSample?.brand,
          kitchen: foodComponentSample?.kitchen
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      enabled: !!foodComponentSample?.name,
      keepPreviousData: true
    }
  );

  const componentOptions = useMemo(
    () =>
      foodComponentData?.data
        ? foodComponentData.data.map((component) => ({
            value: component.id,
            label: component.name.en
          }))
        : [],
    [foodComponentData]
  );

  const options = useMemo(
    () =>
      sortBy(retailList, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        label: `${food.name.en} (${food.size})`
      })),
    [retailList, existRetail]
  );

  const handleDataChange = () => {
    if (currentRetailItem && currentRetailItem.priority && currentRetailItem.id) {
      retailRef.current?.close();

      const newRetailItem = {
        ...currentRetailItem,
        foodModifiers:
          currentRetailItem.foodModifiers?.map((modifier) => ({
            ...modifier,
            componentOptions: modifier.componentOptions?.map((option) => option.id) || []
          })) || []
      };

      AddToTable(newRetailItem);
    }
  };

  useEffect(() => {
    setCurrentRetailItem(retailItem);
  }, [retailItem]);

  useEffect(() => {}, [currentRetailItem]);

  const handleAddModifier = () => {
    setCurrentRetailItem((prevItem) => {
      const newModifier = {
        name: { en: '', ar: '' },
        type: '',
        componentOptions: [],
        otherOptions: []
      };
      const updatedModifiers = [...(prevItem?.foodModifiers || []), newModifier];
      return { ...prevItem, foodModifiers: updatedModifiers };
    });
  };

  const handleRemoveModifier = (index: number) => {
    setCurrentRetailItem((prevItem) => {
      const updatedModifiers = prevItem?.foodModifiers?.filter((_, modIndex) => modIndex !== index) || [];
      return { ...prevItem, foodModifiers: updatedModifiers };
    });
  };

  const { data: currentFood } = useQuery(
    ['currentComponentList', currentRetailItem.id],
    () => selectedFoodData(currentRetailItem.id),
    {
      enabled: !!currentRetailItem.id
    }
  );

  const { data: foodComponentList } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          ids: currentFood?.components.map((comp) => comp.id) || []
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!currentFood?.components,
      keepPreviousData: true
    }
  );

  return (
    <div>
      <Modal ref={retailRef} onClose={onClose}>
        <div className="flex flex-col max-h-screen overflow-y-auto">
          {!retailItem.id && (
            <Select
              label="Item"
              onChange={(data: any) => {
                setCurrentRetailItem({ ...currentRetailItem, id: data.value, priority: +existRetail.length + 1 });
                handleSelectedRetailItem && handleSelectedRetailItem(data.value);
              }}
              onInputChange={(data: string, action: any) =>
                setFilterName && handleSearch({ text: data, action, name: setFilterName })
              }
              placeholder={'Search...'}
              data-test="addon-picker-addon-select"
              options={options}
              isLoading={isLoading}
              value={currentRetailItem.id}
            />
          )}
          {currentFood && currentFood.name && (
            <div className="mb-3">
              <b>{currentFood.name.en}</b>
            </div>
          )}
          <Input
            label="Price"
            type="number"
            value={currentRetailItem.retailPrice}
            onChange={(e) =>
              setCurrentRetailItem({
                ...currentRetailItem,
                retailPrice: +e.target.value
              })
            }
          />

          <SelectMUI
            isMulti
            label="Retail Tag"
            options={retailTagsOptions}
            value={currentRetailItem.retailTagList ? currentRetailItem.retailTagList : []}
            onChange={(selectedOptions) => {
              // Get the selected option values
              const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

              // Preserve existing tags not present in retailTagsOptions
              const allTags = [...new Set([...(currentRetailItem.retailTagList || []), ...selectedValues])];
              setCurrentRetailItem({
                ...currentRetailItem,
                retailTagList: allTags
              });
            }}
          />

          <div className="flex flex-row mb-3"></div>

          <div className="flex  ">
            <div className="field mr-5 flex flex-col ">
              <label className="label">Start Date</label>
              <div className="flex flex-row">
                <DatePicker
                  selected={currentRetailItem?.startDate ? new Date(currentRetailItem.startDate) : null}
                  onChange={(date) => setCurrentRetailItem({ ...currentRetailItem, startDate: date })}
                  dateFormat="yyyy/MM/dd"
                  className="input"
                  placeholderText="Select start date"
                />
                {currentRetailItem?.startDate && (
                  <Button
                    danger
                    icon="fas fa-trash"
                    onClick={() => setCurrentRetailItem({ ...currentRetailItem, startDate: null })}
                    data-test="remove-start-date-button"
                    className="-ml-8"
                  />
                )}
              </div>
            </div>
            <div className="field flex flex-col ">
              <label className="label">End Date</label>
              <div className="flex flex-row">
                <DatePicker
                  selected={currentRetailItem?.endDate ? new Date(currentRetailItem.endDate) : null}
                  onChange={(date) => setCurrentRetailItem({ ...currentRetailItem, endDate: date })}
                  dateFormat="yyyy/MM/dd"
                  className="input"
                  placeholderText="Select end date"
                />
                {currentRetailItem?.endDate && (
                  <Button
                    danger
                    icon="fas fa-trash"
                    onClick={() => setCurrentRetailItem({ ...currentRetailItem, endDate: null })}
                    data-test="remove-end-date-button"
                    className="-ml-8"
                  />
                )}
              </div>
            </div>
            <div className="field mt-8 ml-8 flex flex-row w-32  justify-between mt-3">
              <label className="label">Disabled</label>
              <label
                className={cx(' cursor-pointer h-7 w-12 p-1 rounded-full', {
                  'toggle-left bg-red-500': currentRetailItem.disabled,
                  'toggle-right bg-gray-500': !currentRetailItem.disabled
                })}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={currentRetailItem.disabled}
                  onChange={() => setCurrentRetailItem({ ...currentRetailItem, disabled: !currentRetailItem.disabled })}
                />
                <div
                  className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                    'ml-5': !currentRetailItem.disabled
                  })}
                ></div>
              </label>
            </div>
            <div className="field mt-8 ml-8 flex flex-row w-32  justify-between mt-3">
              <label className="label">Swap Available</label>
              <label
                className={cx(' cursor-pointer h-7 w-12 p-1 rounded-full', {
                  'toggle-left bg-green-500': currentRetailItem.swapAvailable,
                  'toggle-right bg-gray-500': !currentRetailItem.swapAvailable
                })}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={currentRetailItem.swapAvailable}
                  onChange={() => setCurrentRetailItem({ ...currentRetailItem, swapAvailable: !currentRetailItem.swapAvailable })}
                />
                <div
                  className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                    'ml-5': !currentRetailItem.swapAvailable
                  })}
                ></div>
              </label>
            </div>
          </div>

          {/* FoodModifiers Section */}
          <div className="mb-3">
            <label className="label">Food Modifiers</label>
            {currentRetailItem?.foodModifiers && currentRetailItem.foodModifiers.length > 0 ? (
              currentRetailItem.foodModifiers.map((modifier, index) => (
                <div key={modifier.id || index} className="mb-3 pb-3 border-b border-gray-300">
                  <div className="flex flex-row space-x-4">
                    <div className="flex-1">
                      <label className="label">Name EN</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={modifier.name?.en || ''}
                        onChange={(e) => {
                          const updatedModifiers =
                            currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                              modIndex === index ? { ...mod, name: { ...mod.name, en: e.target.value } } : mod
                            ) || [];
                          setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <label className="label">Name AR</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={modifier.name?.ar || ''}
                        onChange={(e) => {
                          const updatedModifiers =
                            currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                              modIndex === index ? { ...mod, name: { ...mod.name, ar: e.target.value } } : mod
                            ) || [];
                          setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <label className="label">Type</label>
                      <Select
                        value={modifier.type || ''}
                        onChange={(data: any) => {
                          const updatedModifiers =
                            currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                              modIndex === index ? { ...mod, type: data.value } : mod
                            ) || [];
                          setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                        }}
                        options={[
                          { value: 'ADD', label: 'Add' },
                          { value: 'REPLACE', label: 'Replace' },
                          { value: 'OPTION', label: 'Option' }
                        ]}
                        styles={{
                          container: (provided: any) => ({
                            ...provided,
                            width: '100%'
                          })
                        }}
                      />
                    </div>
                    <Button
                      danger
                      icon="fas fa-trash"
                      onClick={() => handleRemoveModifier(index)}
                      data-test="remove-modifier-button"
                    />
                  </div>
                  {modifier.type === 'REPLACE' && !!currentFood && (
                    <div className="field mt-3">
                      <label className="label">Source Component ID</label>
                      <Select
                        options={foodComponentList?.data?.map((comp) => ({ value: comp.id, label: comp.name.en })) || []}
                        value={modifier.sourceComponentID || null}
                        onChange={(selectedOption) => {
                          const updatedModifiers =
                            currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                              modIndex === index ? { ...mod, sourceComponentID: selectedOption?.value || '' } : mod
                            ) || [];
                          setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                        }}
                        placeholder="Select source component"
                        isLoading={isLoadingFoodComponents}
                        styles={{
                          container: (provided: any) => ({
                            ...provided,
                            width: '80%',
                            maxHeight: '50px',
                            overflowY: 'auto'
                          })
                        }}
                      />
                    </div>
                  )}
                  {modifier.type === 'OPTION' && (
                    <div className="field mt-3">
                      <label className="label">Other Options</label>
                      <Select
                        isMulti
                        options={[
                          { value: 'hot', label: 'Hot' },
                          { value: 'cold', label: 'Cold' }
                        ]}
                        value={modifier.otherOptions?.map((option) => ({ value: option, label: option })) || []}
                        onChange={(selectedOptions) => {
                          const updatedModifiers =
                            currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                              modIndex === index
                                ? {
                                    ...mod,
                                    otherOptions: selectedOptions ? selectedOptions.map((option) => option.value) : []
                                  }
                                : mod
                            ) || [];
                          setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                        }}
                      />
                    </div>
                  )}
                  {(modifier.type === 'ADD' || modifier.type === 'REPLACE') && (
                    <TableContainer component={Paper} className="mb-3">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Component Options</TableCell>
                            <TableCell>Current ones</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <SelectMUI
                                isMulti
                                data-test="selectComponentSelector"
                                placeholder="Select Component"
                                id="componentInputSelector"
                                options={componentOptions}
                                // @ts-ignore
                                value={modifier?.componentOptions?.map((option) => option.id) || []}
                                isLoading={isLoadingFoodComponents}
                                onChange={(selectedOptions) => {
                                  setCurrentRetailItem((prevItem) => {
                                    const updatedModifiers =
                                      prevItem?.foodModifiers?.map((mod, modIndex) => {
                                        if (modIndex === index) {
                                          const updatedOptions = selectedOptions
                                            ? selectedOptions.map((option: any) => {
                                                return {
                                                  id: option.value,
                                                  name: { en: option.label }
                                                };
                                              })
                                            : [];

                                          return { ...mod, componentOptions: updatedOptions };
                                        }
                                        return mod;
                                      }) || [];

                                    return {
                                      ...prevItem,
                                      foodModifiers: updatedModifiers
                                    };
                                  });
                                }}
                                onInputChange={(data: any, action: any) => {
                                  handleSearch({
                                    text: data,
                                    action,
                                    name: setCurrentComponentName
                                  });
                                }}
                                isDisabled={false}
                              />
                            </TableCell>
                            <TableCell>{modifier?.componentOptions?.map((option) => option.name.en).join(', ') || ''}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              ))
            ) : (
              <p>No modifiers added yet.</p>
            )}
            <Button primary icon="fas fa-plus" onClick={handleAddModifier} />
          </div>

          <Button
            warning
            content="Confirm"
            disabled={!currentRetailItem?.id}
            onClick={handleDataChange}
            data-test="addon-picker-confirm-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default RetailPicker;
