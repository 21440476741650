import { PrototypeAction } from '@calo/dashboard-types';
import { Icon } from '@iconify/react';
import ShareIcon from '@mui/icons-material/Share';
import { CardMedia, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { getHostName } from 'lib/helpers';
import { IngredientType } from 'lib/interfaces';
import { maxBy } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import StatusBox from 'views/ChefPlayground/Shared/StatusBox';

interface IngredientRowProps {
  ingredient: IngredientType;
  commentClickHandler: (ingredient: any) => void;
}

const IngredientRow = ({ ingredient, commentClickHandler }: IngredientRowProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const comments = ingredient.prototypeActions?.filter((action) => action.type === PrototypeAction.comment) ?? [];

  let lastComment = '';
  if (comments.length > 0) {
    const lastCommentAction: any = maxBy(comments, (comment) => new Date(comment.createdAt));
    lastComment = lastCommentAction.type === PrototypeAction.comment ? lastCommentAction.message : '';
  }

  const shareButtonClickHandler = async () => {
    const hostName = getHostName();
    const link = `${hostName}${Routes.playgroundIngredient.replace(':slug', ingredient.slug)}`;

    try {
      await navigator.clipboard.writeText(link);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000); // Hide tooltip after 3 seconds
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/ingredients/${ingredient.id}/square@1x.jpg`;

  return (
    <TableRow>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <CardMedia
            component="img"
            image={displayImage}
            alt="placeholder"
            sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '8px' }}
            onError={(e: any) => {
              e.target.src = noImagePlaceholder;
            }}
          />
          <Link to={Routes.playgroundIngredientSlug.replace(':slug', ingredient.slug)}>
            <Typography sx={{ fontSize: '14px' }}>{ingredient.name.en}</Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
          <Typography sx={{ fontSize: '14px' }}>
            {ingredient.prototypeActions?.find((action) => action.type === PrototypeAction.create)?.actor.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
          <StatusBox status={ingredient.stage} />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '4px', width: '90%' }}>
          <Link to={'#'}>
            <Typography
              sx={{
                fontSize: '14px',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {lastComment}
            </Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '24px' }}>
          <Stack>
            <Tooltip
              title="Link Copied"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={() => setTooltipOpen(false)}
            >
              <IconButton onClick={shareButtonClickHandler}>
                <ShareIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>

          <IconButton onClick={() => commentClickHandler(ingredient)}>
            <Icon icon="bi:chat" width="24" height="24" color={caloTheme.palette.primary500} />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default IngredientRow;
