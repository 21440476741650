import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { generatePath, RouteComponentProps, useLocation } from 'react-router';

import { Permission } from '@calo/dashboard-types';
import { Brand, RetailBranch } from '@calo/types';

import { getListWithParams } from 'actions';
import { Button, CaloLoader } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import RetailMenuRow from './RetailMenuRow';
import Settings from './Settings';

type AddonsListProps = RouteComponentProps;

const retailBranchValues = Object.values(RetailBranch);

const RetailMenu = ({ history }: AddonsListProps) => {
  const roles = useUserRoles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState({
    brand: Brand.CALO,
    branch: retailBranchValues.includes(location.pathname.split('/')[2] as RetailBranch)
      ? location.pathname.split('/')[2]
      : RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    const path = generatePath(Routes.retailMenu, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));

    history.push({
      pathname: path,
      search: searchParams.toString()
    });
  }, [filters.branch]);

  const { data: retailMenu, isLoading } = useQuery<any, Error, any>(
    ['retail-menu', { brand: filters.brand, branch: filters.branch }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Retail Menu / {filters.branch}</li>
              </ul>
            </div>
          </div>
          {(roles.includes(Permission.CREATE_ADDONS_SUBSCRIPTION) || roles.includes(Permission.UPDATE_ADDONS_SUBSCRIPTION)) && (
            <div className="level-right">
              <div className="level-item">
                <Button
                  icon="fas fa-pencil"
                  onClick={() => {
                    console.log(retailMenu);
                    history.push(Routes.retailNewMenu.replace(':brand', filters.brand).replace(':branch', filters.branch));
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <Settings onFilter={setFilters} filters={filters} />
      {isLoading ? (
        <CaloLoader />
      ) : retailMenu ? (
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">{retailMenu && <RetailMenuRow addons={retailMenu} />}</div>
        </div>
      ) : (
        <span className="flex w-full text-3xl mt-4 justify-center font-bold text-gray-400">No Retail Menu added</span>
      )}
    </>
  );
};

export default RetailMenu;
