import { useFormik } from 'formik';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { isNumber, omit } from 'lodash-es';

interface FoodComponentWithSellingPrice extends Omit<FoodComponent, BaseOmit> {
  sellingPrice?: number;
}

export default (
  foodComponent: FoodComponentWithSellingPrice | undefined,
  onSubmit: (values: FoodComponentWithSellingPrice) => any
) =>
  useFormik<FoodComponentWithSellingPrice>({
    initialValues: {
      ...(omit(foodComponent, ['id', 'createdAt', 'updatedAt', 'usedOnMenu', 'lastUsedOnMenu']) as FoodComponent),
      micronutrients: {
        addedSugar: foodComponent?.micronutrients?.addedSugar || 0,
        cholesterol: foodComponent?.micronutrients?.cholesterol || 0,
        saturatedFats: foodComponent?.micronutrients?.saturatedFats || 0,
        sodium: foodComponent?.micronutrients?.sodium || 0,
        totalSugar: foodComponent?.micronutrients?.totalSugar || 0,
        transFats: foodComponent?.micronutrients?.transFats || 0
      },
      childComponents: (foodComponent?.childComponents ?? []).map((child) => ({ id: child.id, quantity: child.quantity })),
      sellingPrice: foodComponent?.sellingPrice
    },
    validate: (values: FoodComponentWithSellingPrice) => {
      const errors: any = {};

      if (!values.name!.en) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!values.name!.ar) {
        errors.name = {
          ...errors.name,
          ar: true
        };
      }

      if (!isNumber(values.macros!.cal)) {
        errors.macros = {
          ...errors.macros,
          cal: true
        };
      }
      if (!isNumber(values.macros!.protein)) {
        errors.macros = {
          ...errors.macros,
          protein: true
        };
      }
      if (!isNumber(values.macros!.carbs)) {
        errors.macros = {
          ...errors.macros,
          carbs: true
        };
      }
      if (!isNumber(values.macros!.fat)) {
        errors.macros = {
          ...errors.macros,
          fat: true
        };
      }

      if (!isNumber(values.micronutrients!.addedSugar)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          addedSugar: true
        };
      }
      if (!isNumber(values.micronutrients!.cholesterol)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          cholesterol: true
        };
      }
      if (!isNumber(values.micronutrients!.saturatedFats)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          saturatedFats: true
        };
      }
      if (!isNumber(values.micronutrients!.sodium)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          sodium: true
        };
      }
      if (!isNumber(values.micronutrients!.totalSugar)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          totalSugar: true
        };
      }
      if (!isNumber(values.micronutrients!.transFats)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          transFats: true
        };
      }

      if (!values.status) {
        errors.status = true;
      }

      if (!values.cost) {
        errors.cost = true;
      }

      if (values.sections) {
        for (const section of values.sections) {
          if (section.quantity <= 0) {
            errors[section.category] = true;
          }
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      const { purchasingCost: _, ...rest } = values;
      try {
        await onSubmit(rest);
      } catch (error) {
        console.log(error);
      }
    }
  });
