import { RefObject, useEffect, useRef, useState } from 'react';

import { FoodStatus, Permission, UpdateFoodReq } from '@calo/dashboard-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ConfirmationModal, ModalRef } from 'components';
import { MealTabs } from 'lib/enums';
import { Food, FoodComponent } from 'lib/interfaces';
import Header from '../../Header';
import TabsComponent from './TabsComponent';

interface StickyHeaderProps {
  isSubmitting: boolean;
  dirty: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
  values: UpdateFoodReq;
  setValues: (values: UpdateFoodReq) => void;
  sectionRefs: RefObject<HTMLDivElement>[];
  isValid: boolean;
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
  roles: any;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string;
    week?: number;
  }[];
  usedOnMenuButtonColor: string[];
  selectedFoodComponents: FoodComponent[];
  childComponents: FoodComponent[];
}

const StickyHeader = ({
  roles,
  isSubmitting,
  dirty,
  setFoodWithAllSizes,
  usedOnMenuButtonColor,
  isDisabled,
  handleSubmit,
  filteredFoodUsed,
  foodWithAllSizes,
  values,
  setValues,
  sectionRefs,
  isValid,
  selectedFood,
  childComponents,
  selectedFoodComponents,
  setSelectedFood
}: StickyHeaderProps) => {
  const [tab, setTab] = useState(0);
  const isTabClickRef = useRef(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    isTabClickRef.current = true;
    sectionRefs[newValue].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (isTabClickRef.current) {
          isTabClickRef.current = false;
          return;
        }
        const visibleSection = entries.reduce(
          (max, entry) => (entry.intersectionRatio > max.intersectionRatio ? entry : max),
          entries[0]
        );
        const index = sectionRefs.findIndex((ref) => ref.current === visibleSection.target);
        if (index !== -1) {
          setTab(index);
        }
      },
      {
        rootMargin: '-50% 0px -50% 0px'
      }
    );
    for (const ref of sectionRefs) {
      if (ref.current) {
        observer.observe(ref.current);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  const confirmModalRef = useRef<ModalRef>();
  const handleConfirmation = () => {
    if (filteredFoodUsed?.length > 0) {
      confirmModalRef.current?.open();
    } else {
      handleSubmit();
    }
  };

  const isValidForRelease = () => {
    if (
      !values.name?.ar ||
      (values.name?.ar && values.name?.ar.length < 3) ||
      (values.name?.ar && values.name?.ar.length > 50) ||
      values.type?.length === 0 ||
      !isValid
    ) {
      return false;
    }
    return true;
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      sx={{
        backgroundColor: caloTheme.palette.white,
        p: 2,
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        marginRight: '-35px !important',
        marginTop: '-35px !important'
      }}
    >
      <Header
        roles={roles}
        selectedFood={selectedFood}
        foodWithAllSizes={foodWithAllSizes}
        setSelectedFood={setSelectedFood}
        setFoodWithAllSizes={setFoodWithAllSizes}
        filteredFoodUsed={filteredFoodUsed}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
        selectedFoodComponents={selectedFoodComponents}
        childComponents={childComponents}
        isDisabled={isDisabled}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TabsComponent
          tab={tab}
          handleTabChange={handleTabChange}
          tabLabels={Object.values(MealTabs).map((mealTab) => mealTab)}
        />
        {roles.includes(Permission.UPDATE_FOOD) && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
            <LoadingButton
              loadingPosition={'center'}
              id="saveButtonExactMeal"
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting || isDisabled}
              onClick={() => handleConfirmation()}
              sx={{
                textTransform: 'capitalize',
                color: caloTheme.palette.black,
                border: 1,
                borderRadius: '8px',
                borderColor: caloTheme.palette.black,
                py: '10px',
                px: '26px',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 600,
                ':hover': {
                  borderColor: caloTheme.palette.black,
                  backgroundColor: caloTheme.palette.white
                },
                ':disabled': {
                  borderColor: caloTheme.palette.grey['400']
                }
              }}
            >
              Save
            </LoadingButton>
            {selectedFood.status === FoodStatus.draft && (
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting || !isValidForRelease() || isDisabled}
                onClick={() => {
                  setValues({
                    ...values,
                    status: FoodStatus.approved
                  });
                  handleConfirmation();
                }}
                sx={{
                  textTransform: 'capitalize',
                  color: caloTheme.palette.white,
                  backgroundColor: caloTheme.palette.primary500,
                  border: 1,
                  borderRadius: '8px',
                  borderColor: caloTheme.palette.primary500,
                  py: '10px',
                  px: '16px',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontWeight: 600,
                  ':hover': {
                    color: caloTheme.palette.primary500,
                    backgroundColor: caloTheme.palette.white,
                    borderColor: 'transparent'
                  },
                  ':disabled': {
                    color: caloTheme.palette.white,
                    borderColor: caloTheme.palette.grey['400'],
                    backgroundColor: caloTheme.palette.grey['400']
                  }
                }}
              >
                Release
              </LoadingButton>
            )}
          </Stack>
        )}
        <ConfirmationModal ref={confirmModalRef} values={values} action={handleSubmit}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex">
              <label className="label">The change will take effect on the next new generated menu </label>
            </span>
          </div>
        </ConfirmationModal>
      </Stack>
    </Stack>
  );
};

export default StickyHeader;
