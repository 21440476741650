import { FormOperation, Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

import { Permission, ProcessingStage, PrototypeAction } from '@calo/dashboard-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';

import { addPrototypeComponentAction, approvePrototypeComponent, deleteFoodComponent } from 'actions';
import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import { ModalRef } from 'components/Modal';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import StatusBox from 'views/ChefPlayground/Shared/StatusBox';
import ComponentNameAndUsage from './ComponentNameAndUsage';
import Container from './Container';
import { styles } from './styles';

type ComponentHeaderCardProps = {
  isValid: boolean;
  isSubmitting: boolean;
  isEdit: boolean;
  route: Routes;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isDisabled?: boolean;
  operation: FormOperation;
  foodComponent?: FoodComponent;
  syncComponentsPopupRef?: React.MutableRefObject<ModalRef | undefined>;
  filteredFoodUsed?: any[];
  componentFoodList?: any[];
  isFoodLoading?: boolean;
  refetch?: () => void;
};

const ComponentHeaderCard = ({
  isValid,
  isSubmitting,
  isEdit,
  handleSubmit,
  isDisabled,
  route,
  operation,
  foodComponent,
  syncComponentsPopupRef,
  filteredFoodUsed = [],
  componentFoodList = [],
  isFoodLoading = false,
  refetch
}: ComponentHeaderCardProps) => {
  const userRoles = useUserRoles();
  const history = useHistory();
  const deletionRef = useRef<ModalRef>(null);

  const { mutateAsync: deleteMutation } = useMutation(deleteFoodComponent);

  const { mutate: createAction, isLoading: actionLoading } = useMutation(addPrototypeComponentAction, {
    onSuccess: () => {
      refetch?.();
    }
  });

  const { mutate: approveComponent, isLoading: approveLoading } = useMutation(approvePrototypeComponent, {
    onSuccess: () => {
      refetch?.();
    }
  });

  const handleDeleteComponent = async () => {
    if (operation === FormOperation.update && foodComponent) {
      await deleteMutation({ id: foodComponent.id });
      history.push(Routes.foodComponentList);
    }
  };

  const handleClosePopup = () => {
    deletionRef.current?.close();
  };

  const renderPrototypeActions = () => (
    <>
      {foodComponent?.stage === ProcessingStage.draft && (
        <Button
          variant="outlined"
          disabled={!foodComponent || actionLoading || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_COMPONENT)}
          sx={styles.buttonOutlined}
          onClick={() => createAction({ action: PrototypeAction.pend, id: foodComponent?.id ?? '' })}
        >
          Send for Approval
        </Button>
      )}
      {foodComponent?.stage === ProcessingStage.pending && (
        <Button
          variant="outlined"
          disabled={!foodComponent || approveLoading || !userRoles.includes(Permission.APPROVE_PROTOTYPE_COMPONENT)}
          sx={styles.buttonPrimary}
          onClick={() => approveComponent(foodComponent?.id)}
        >
          Approve
        </Button>
      )}
      {foodComponent?.stage === ProcessingStage.approved && foodComponent.prototype && (
        <StatusBox status={ProcessingStage.approved} />
      )}
    </>
  );

  return (
    <>
      <Container sx={styles.containerStyles}>
        <Stack sx={styles.headerStackStyles}>
          <Stack sx={styles.backButtonStackStyles}>
            <Stack sx={styles.backButtonIconContainerStyles}>
              <Link to={route} style={{ color: 'black' }}>
                <Stack sx={styles.backButtonLinkStyles}>
                  <ArrowBackIosIcon style={styles.arrowBackIconStyles} />
                  <Typography sx={styles.backButtonTextStyles}>Back</Typography>
                </Stack>
              </Link>
            </Stack>
          </Stack>
          {operation === FormOperation.update && (
            <Typography variant="h5" sx={styles.headerTextStyles}>
              {capitalize(foodComponent?.brand)} - {resolveCountry(foodComponent?.country)} -{' '}
              {formatKitchenText(foodComponent?.kitchen)}
            </Typography>
          )}
        </Stack>
        <Stack sx={styles.dividerStyles} />
      </Container>
      <Container sx={styles.mainContainerStyles}>
        <Stack sx={styles.mainStackStyles}>
          {operation === FormOperation.update && (
            <ComponentNameAndUsage foodComponent={foodComponent} filteredFoodUsed={filteredFoodUsed} isDisabled={!!isDisabled} />
          )}
          {operation === FormOperation.create && <Typography sx={styles.createHeaderStyles}>Create Component</Typography>}
          <Stack sx={styles.actionButtonStackStyles}>
            {operation === FormOperation.update && userRoles.includes(Permission.DELETE_FOOD_COMPONENT) && (
              <Button
                variant="outlined"
                disabled={isDisabled || isFoodLoading}
                sx={styles.deleteButtonStyles}
                onClick={() => deletionRef.current?.open()}
              >
                {isFoodLoading ? 'loading' : 'Delete'}
              </Button>
            )}
            {operation === FormOperation.update && syncComponentsPopupRef && (
              <Button
                onClick={() => syncComponentsPopupRef?.current?.open()}
                disabled={isDisabled}
                variant="outlined"
                sx={styles.updateButtonStyles}
              >
                Update Components
              </Button>
            )}
            {operation === FormOperation.update && renderPrototypeActions()}
            <Button
              data-test="addComponentButton"
              variant="contained"
              sx={styles.saveButtonStyles}
              disabled={
                (isDisabled && !userRoles.includes(Permission.APPROVE_PROTOTYPE_COMPONENT)) || !isValid || isSubmitting || isEdit
              }
              onClick={() => handleSubmit()}
            >
              {operation === FormOperation.create ? 'Add Component' : 'Save Changes'}
            </Button>
          </Stack>
        </Stack>
        {operation === FormOperation.update && (
          <DeleteConfirmationPopup
            type="component"
            deletionRef={deletionRef}
            typeUsage={
              componentFoodList.map((meal) => ({
                name: meal.name.en,
                id: meal.id
              })) || []
            }
            isLoading={isFoodLoading}
            onClose={handleClosePopup}
            onDelete={handleDeleteComponent}
          />
        )}
      </Container>
    </>
  );
};

export default ComponentHeaderCard;
