import { useRef, useState } from 'react';

import { isEmpty, uniqBy } from 'lodash-es';
import { QueryFunction, useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Brand, Country, RetailBranch } from '@calo/types';

import { RetailMenuFoodItem, UpdateRetailMenuReq } from '@calo/dashboard-types';
import { createRetailMenu, getListWithParams, updateRetailMenu } from 'actions';
import { Button, Card, Icon, ModalRef, RetailMenuPicker, RetailMenuTable } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';
import { getCountryFromBranch, getKitchenFromBranch } from '../retailHelpers';
import useRetailMenuForm from './useRetailMenuForm';

// @ts-ignore
const getListWithParamsRetail: QueryFunction<[string, Record<string, any>]> = async (e) => {
  try {
    return await getListWithParams(e);
  } catch (error) {
    console.log(error);
    return ['', {}]; // Return a default value of the expected type
  }
};

const ExactRetailMenu = () => {
  const { brand, branch } = useParams<{ country: Country; brand: Brand; branch: RetailBranch }>();
  const [filterName, setFilterName] = useState<string>();
  const [selectedRetailFoodList, setSelectedRetailFoodList] = useState<Food[]>([]);
  const { data } = useQuery<any, Error, any>(
    ['retail-menu', { brand: brand || Brand.CALO, branch: branch }],
    getListWithParamsRetail,
    {
      suspense: true
    }
  );

  const isRetailMenuNotFound = isEmpty(data);
  const retailMenu = isEmpty(data)
    ? {
        country: getCountryFromBranch(branch),
        branch,
        brand,
        food: []
      }
    : (data as any);

  const { data: retailMenuList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: getCountryFromBranch(branch),
          brand,
          isDeleted: false,
          kitchen: getKitchenFromBranch(branch)
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updateRetailMenu);
  const { mutateAsync: createMutation } = useMutation(createRetailMenu);

  const onSubmit = async (values: UpdateRetailMenuReq) => {
    try {
      if (isRetailMenuNotFound) {
        await createMutation(
          {
            food: values.food,
            country: retailMenu.country,
            brand: retailMenu.brand,
            branch: retailMenu.branch
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.retailMenu, {
                brand: retailMenu.brand,
                branch: retailMenu.branch
              });
              history.push(path);
            }
          }
        );
      } else {
        await updateMutation(
          {
            food: values.food,
            country: retailMenu.country,
            brand: retailMenu.brand,
            branch: retailMenu.branch,
            menuId: values.menuId
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.retailMenu, {
                brand: retailMenu.brand,
                branch: retailMenu.branch
              });
              history.push(path);
            }
          }
        );
      }
    } catch {
      toast('error', { type: 'error', autoClose: 2000 });
    }
  };

  const { values, isSubmitting, isValid, dirty, handleSubmit, setValues } = useRetailMenuForm(
    retailMenu,
    brand,
    branch,
    onSubmit
  );

  const retailRef = useRef<ModalRef>();

  const [retailItem, setRetailItem] = useState<RetailMenuFoodItem>({
    id: undefined!,
    priority: undefined!,
    startDate: undefined!,
    endDate: undefined!,
    retailPrice: 0,
    retailTagList: []
  });

  const AddToTable = (value: RetailMenuFoodItem) => {
    let restOfFood = values.food.filter((item) => item.id !== value.id);
    // convert resetOffood foodModifiers Component options to array of ids only
    restOfFood = restOfFood?.map((foodItem) => ({
      ...foodItem,
      foodModifiers: foodItem?.foodModifiers?.map((modifier) => ({
        ...modifier,
        componentOptions: modifier?.componentOptions?.map((option) => (typeof option === 'string' ? option : option?.id))
      }))
    }));

    setValues({
      food: [
        ...restOfFood,
        {
          id: value.id,
          priority: value.priority,
          startDate: value.startDate,
          endDate: value.endDate,
          retailPrice: value.retailPrice,
          retailTagList: value.retailTagList,
          foodModifiers: value.foodModifiers,
          disabled: value.disabled,
          swapAvailable: value.swapAvailable
        }
      ],
      branch,
      brand,
      menuId: retailMenu.id
    });
    retailRef.current?.close();
    setRetailItem({
      id: undefined!,
      priority: undefined!,
      startDate: undefined!,
      endDate: undefined!,
      retailPrice: 0,
      foodModifiers: [],
      disabled: false,
      swapAvailable: false
    });
  };

  const editRow = (value: RetailMenuFoodItem) => {
    setRetailItem({
      id: value.id,
      priority: value.priority,
      startDate: value.startDate,
      endDate: value.endDate,
      retailPrice: value.retailPrice,
      retailTagList: value.retailTagList,
      foodModifiers: value.foodModifiers,
      disabled: value.disabled
    });
    retailRef.current?.open();
  };

  const handleSelectedRetailItem = (id: string) => {
    const newRetailItem = retailMenuList?.data.find((e) => e.id === id);
    if (newRetailItem) {
      setSelectedRetailFoodList((old) => [...old, newRetailItem]);
    }
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.retailMenu}>Retail Menu</Link>
                </li>
                <li>Edit - {retailMenu.branch}</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {retailMenu.brand && retailMenu.brand === Brand.MEALO ? (
                <Icon name="mealo" size={28} className="w-24 -mb-24" />
              ) : (
                <Icon name="calo" size={8} className="w-32 -mb-4" />
              )}
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="mx-4">
          <div className="card w-full">
            <header className="card-header bg-black">
              <p className="card-header-title w-full text-white">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                Info
              </p>
              <p className="card-header-title flex float-right text-white">
                <h1>{retailMenu.kitchen}</h1>
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="menuId" value={retailMenu.id} />
                <RetailMenuPicker
                  isLoading={isLoading}
                  retailRef={retailRef}
                  AddToTable={AddToTable}
                  retailItem={retailItem}
                  existRetail={values.food}
                  setFilterName={setFilterName}
                  onClose={() =>
                    setRetailItem({
                      id: undefined!,
                      priority: undefined!,
                      startDate: undefined!,
                      endDate: undefined!,
                      retailPrice: 0
                    })
                  }
                  retailList={(retailMenuList?.data || []).filter((f) => !f.deletedAt)}
                  handleSelectedRetailItem={handleSelectedRetailItem}
                />
                <Button
                  primary
                  type="submit"
                  content="Save"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={!dirty || !isValid || isSubmitting}
                  data-test="retail-menu-exact-save-button"
                />
              </form>
              <Card title="" className="my-2">
                <div className="float-right -mt-8">
                  <Button
                    icon="fas fa-plus"
                    onClick={() => retailRef.current?.open()}
                    data-test="retail-menu-exact-plus-button"
                  />
                </div>
                <RetailMenuTable
                  values={values}
                  setValues={setValues}
                  retailValues={values.food}
                  editRow={(k) => {
                    editRow(k);
                  }}
                  retailMenu={uniqBy(
                    [...(retailMenuList?.data || []).filter((f) => !f.deletedAt), ...retailMenu.food, ...selectedRetailFoodList],
                    'id'
                  ).map((selectedFood) => {
                    const food = values.food.find((r) => r.id === selectedFood.id);
                    return {
                      ...selectedFood,
                      retail: {
                        startDate: food?.startDate,
                        endDate: food?.endDate,
                        ...selectedFood.retail,
                        disabled: food?.disabled || false,
                        swapAvailable: food?.swapAvailable || false
                      }
                    };
                  })}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExactRetailMenu;
