import { format, parseISO } from 'date-fns/fp';
import { sortBy } from 'lodash';
interface AddonsProps {
  addons: any;
}

const RetailMenuRow = ({ addons }: AddonsProps) => {
  const sortedList = sortBy(addons.food, 'priority');

  return (
    <div className="card has-table has-table-container-upper-radius ">
      <header className="card-header bg-black w-full">
        <>
          <p className="card-header-title text-white">Retail Menu</p>
          <p className=" text-bold text-green-500 float-right mr-3 my-4 w-12">CALO</p>
        </>
      </header>
      <div className="table-container">
        <table className="table mt-4 is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Food Tags</th>
              <th>Retail Price</th>
              <th>Size</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Disabled</th>
              <th>Swap Available</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((f: any) => (
              <tr key={f.id}>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.name.en}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.retail.retailTagList?.join(', ')}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.retail.retailPrice}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.size}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.retail.startDate ? format('yyyy-MM-dd')(parseISO(f.retail.startDate)) : 'N/A'}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.retail.endDate ? format('yyyy-MM-dd')(parseISO(f.retail.endDate)) : 'N/A'}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4  w-16 text-center">
                    {f.retail.disabled ? 'Yes' : 'No'}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4  w-16 text-center">
                    {f.retail.swapAvailable ? 'Yes' : 'No'}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RetailMenuRow;
