import { RetailOrderStatus } from '@calo/dashboard-types';
import { Brand, RetailBranch } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { updateRetailOrderStatus } from 'actions/retail';
import { Routes } from 'lib/enums';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import Settings from './Settings';

interface RetailOrder {
  branch: string;
  items: Array<{
    actions: any[];
    addedByUser: boolean;
    createdAt: string;
    custom: boolean;
    id: string;
    itemId: string;
    itemType: string;
    name: Record<string, unknown>;
    notes: string;
    orderId: string;
    price: {
      amount: number;
      currency: string;
    };
    quantity: number;
    status: string;
    swapped: boolean;
    updatedAt: string;
  }>;
  total: number;
  status: string;
  id: string;
  createdAt: string;
  statusUpdatedAt: string;
}

const RetailOrderMenu = ({ history }: any) => {
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState({
    brand: Brand.CALO,
    branch: RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    const path = generatePath(Routes.retailOrderList, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }, [filters.country]);

  const {
    data: orderList,
    isLoading,
    refetch,
    isFetching
  } = useQuery<any, Error, RetailOrder[]>(['/retail/orders', { branch: filters.branch }], getListWithParams, {
    suspense: false,
    onSuccess: () => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeStatus = async (orderId: string, status: RetailOrderStatus) => {
    await updateRetailOrderStatus(orderId, status);
    await refetch();
  };

  return (
    <div className="retail-order-menu">
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Retail Orders / {filters.branch}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Settings onFilter={setFilters} filters={filters} />
      <ul>
        {orderList && orderList.length > 0 ? (
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <td>Order</td>
                <td>Customer Name</td>
                <td>Phone Number</td>
                <td>Status</td>
                <td>Created At</td>
                <td>Items</td>
                <td>Note</td>
              </tr>
            </thead>
            <tbody>
              {[...orderList].reverse().map((order, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    {/* @ts-ignore */}
                    <td>{order.user.name}</td>
                    {/* @ts-ignore */}
                    <td>{order.user.phoneNumber}</td>
                    <td>
                      <select
                        value={order.status}
                        onChange={(e) => handleChangeStatus(order.id, e.target.value as RetailOrderStatus)}
                        disabled={isFetching || isLoading}
                      >
                        {Object.values(RetailOrderStatus).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {new Date(order.createdAt).toLocaleDateString()} at {new Date(order.createdAt).toLocaleTimeString()}
                    </td>
                    <td>
                      {order.items.map((item) => (
                        <div key={item.id}>{String(item.name.en)}</div>
                      ))}
                    </td>
                    <td>
                      {order.items.map((item) => (
                        <div key={item.id}>
                          {item.notes && (
                            <div>
                              <div>{String(item.name.en)}:</div>
                              {item.notes.split('\n').map((note, index) => (
                                <div key={index}>{note}</div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p> {isLoading || isFetching ? 'Loading...' : 'No orders found.'}</p>
        )}
      </ul>
    </div>
  );
};

export default RetailOrderMenu;
