import { CreateRetailMenuReq, RetailMenuFoodItem, UpdateRetailMenuReq } from '@calo/dashboard-types';
import { format } from 'date-fns/fp';
import { MenuFood } from 'lib/interfaces';

interface RetailTableProps {
  retailMenu: MenuFood[];
  values: CreateRetailMenuReq | UpdateRetailMenuReq;
  retailValues: RetailMenuFoodItem[];
  editRow: (values: RetailMenuFoodItem) => void;
  setValues: any;
}

const RetailTable = ({ setValues, retailMenu, editRow, values }: RetailTableProps) => {
  const deleteRow = (row: RetailMenuFoodItem) => {
    let restOfFood = values.food.filter((item) => item.id !== row.id);
    restOfFood = restOfFood?.map((foodItem) => ({
      ...foodItem,
      foodModifiers: foodItem?.foodModifiers?.map((modifier) => ({
        ...modifier,
        // @ts-ignore
        componentOptions: modifier?.componentOptions?.map((option) => option.id)
      }))
    }));
    setValues({
      ...values,
      food: restOfFood
    });
  };

  const getUpdatedPrice = (id: string) => {
    return values.food.find((item) => item.id === id)?.retailPrice;
  };

  const getUpdatedTags = (id: string) => {
    return values.food.find((item) => item.id === id)?.retailTagList;
  };

  return (
    <div className="table-container">
      <table className="table mt-4 is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
        <thead>
          <tr>
            <th>Name</th>
            <th>Retail Tags</th>
            <th>Size</th>
            <th>Price</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Disabled</th>
            <th>Swap Available</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-capitalize">
          {retailMenu.map((f: any, index) => (
            <tr key={index}>
              <td>{f.name.en}</td>

              <td>{getUpdatedTags(f.id)?.join(', ')}</td>
              <td>{f.size}</td>
              <td>{getUpdatedPrice(f.id)}</td>
              <td>{f.retail.startDate ? format('yyyy-MM-dd')(new Date(f.retail.startDate)) : 'N/A'}</td>
              <td>{f.retail.endDate ? format('yyyy-MM-dd')(new Date(f.retail.endDate)) : 'N/A'}</td>
              <td>{f.retail.disabled ? 'Yes' : 'No'}</td>
              <td>{f.retail.swapAvailable ? 'Yes' : 'No'}</td>
              <td>
                <div key={index} className="mb-4 flex ">
                  {values.food.map((r) => (r.id === f.id ? r.priority : ''))}
                  <div key={index} className="ml-2">
                    {f.retail?.retailPrice >= 0 && (
                      <i
                        className="fas fa-edit mr-2"
                        onClick={() =>
                          editRow({
                            id: f.id,
                            priority: f.retail.priority,
                            retailPrice: f.retail.retailPrice,
                            retailTagList: f.retail.retailTagList,
                            endDate: f.retail.endDate,
                            startDate: f.retail.startDate,
                            foodModifiers: f.retail.foodModifiers,
                            disabled: f.retail.disabled,
                            swapAvailable: f.retail.swapAvailable
                          })
                        }
                        data-test="retail-menu-table-edit-button"
                      />
                    )}
                    <i
                      className="fas fa-trash mr-4 last:mr-0"
                      onClick={() =>
                        deleteRow({
                          id: f.id,
                          priority: +values.food.find((e) => f.id === e.id)!.priority,
                          retailPrice: f.retailPrice
                        })
                      }
                      data-test="retail-menu-table-delete-button"
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RetailTable;
